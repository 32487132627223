import {
  Box,
  HStack,
  Button,
  Input,
  VStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { DeleteIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { NumInput, PersonPicker } from "../reusable";
import { capitalize, generateId } from "../../utils";
import { useContext } from "react";
import { Context } from "../../App";

const accountTypes = [
  { type: "checking", description: null },
  { type: "credit", description: null },
  { type: "investment", description: "Stocks, Bonds, IRA..." },
  { type: "loan", description: "Car, Student, Home, Boat..." },
  { type: "savings", description: "Savings Account, 401k, HSA..." },
];

export const AccountsInput = () => {
  const context = useContext(Context);

  const addAccount = (type) => {
    let upd = structuredClone(context.accounts);

    const blanks = {
      checking: {
        id: null,
        owner: null,
        type: "checking",
        balance: null,
        name: null,
      },
      credit: {
        id: null,
        owner: null,
        type: "credit",
        balance: null,
        name: null,
        limit: null,
        interestRate: null,
      },
      investment: {
        id: null,
        owner: null,
        type: "investment",
        balance: null,
        name: null,
        interestRate: null,
      },
      loan: {
        id: null,
        owner: null,
        type: "loan",
        balance: null,
        name: null,
        interestRate: null,
      },
      savings: {
        id: null,
        owner: null,
        type: "savings",
        balance: null,
        name: null,
        interestRate: null,
      },
    };

    const newVal = structuredClone(blanks[type]);
    newVal.id = generateId();

    if (
      context.accounts?.length > 0 &&
      typeof context.accounts[0] !== "undefined"
    ) {
      upd.push(newVal);
    } else {
      upd = [newVal];
    }

    save(upd);
  };

  const updateValue = (field, newVal, i) => {
    let upd = structuredClone(context.accounts);
    upd[i][field] = newVal;
    save(upd);
  };

  const deleteAccount = (i) => {
    let upd = structuredClone(context.accounts);
    save(upd.toSpliced(i, 1));
  };

  const save = (val) => {
    localStorage.setItem("accounts", JSON.stringify(val));
    context.setContext({ ...context, accounts: val });
  };

  return (
    <VStack w="full" spacing={1}>
      <HStack w="full" justifyContent="space-between">
        <Text fontSize="sm" color="gray">
          Accounts
        </Text>
        <Menu>
          <MenuButton
            as={Button}
            size="sm"
            variant="link"
            transition="all 0.2s"
            color={"gray"}
            _hover={{ color: "whiteAlpha.800" }}
            rightIcon={<ChevronDownIcon />}
            _active={{ color: "whiteAlpha.800" }}
          >
            Add
          </MenuButton>
          <MenuList
            backgroundColor="#1e1e1e"
            borderRadius="none"
            borderColor="whiteAlpha.300"
            color="whiteAlpha.800"
          >
            {accountTypes.map((opt) => (
              <MenuItem
                key={opt.type}
                backgroundColor="#1e1e1e"
                _hover={{ backgroundColor: "whiteAlpha.100" }}
                onClick={() => addAccount(opt.type)}
              >
                <VStack spacing={0} align="start">
                  <Text>{capitalize(opt.type)}</Text>
                  {opt.description ? (
                    <Text fontSize="sm" color="whiteAlpha.500">
                      {capitalize(opt.description)}
                    </Text>
                  ) : null}
                </VStack>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </HStack>
      <VStack spacing={2} w="full">
        {context.accounts?.length > 0 ? (
          context.accounts.map((act, i) => {
            return (
              <HStack key={act.id} spacing={0} w="full" align="stretch">
                <VStack spacing={0} h="full" w="full">
                  <HStack spacing={0} w="full">
                    <PersonPicker
                      value={act.owner}
                      onChange={(val) => updateValue("owner", val, i)}
                    />
                    <Box
                      border="1px"
                      h="2.5rem"
                      borderColor="whiteAlpha.300"
                      alignContent="center"
                      px={4}
                    >
                      <Text color="whiteAlpha.800">{capitalize(act.type)}</Text>
                    </Box>
                    <Input
                      color="whiteAlpha.800"
                      borderColor="whiteAlpha.300"
                      _hover={{ borderColor: "whiteAlpha.800" }}
                      borderRadius={0}
                      placeholder="Nickname"
                      defaultValue={act.nickname}
                      _placeholder={{ color: "whiteAlpha.300" }}
                      onChange={(e) =>
                        updateValue("nickname", e.target.value, i)
                      }
                    />
                  </HStack>
                  <HStack spacing={0} w="full">
                    <NumInput
                      value={act.balance}
                      placeholder="Balance"
                      showDollarSign
                      onChange={(val) => updateValue("balance", val, i)}
                    />
                    {act.limit !== undefined ? (
                      <NumInput
                        value={act.limit}
                        placeholder="Limit"
                        showDollarSign
                        onChange={(val) => updateValue("limit", val, i)}
                      />
                    ) : null}
                    {act.interestRate !== undefined ? (
                      <NumInput
                        value={act.interestRate}
                        placeholder="Rate"
                        showPercentSign
                        onChange={(val) => updateValue("interestRate", val, i)}
                      />
                    ) : null}
                  </HStack>
                </VStack>
                <Box
                  border="1px"
                  borderColor="whiteAlpha.300"
                  px={4}
                  onClick={() => deleteAccount(i)}
                  alignContent="center"
                >
                  <DeleteIcon
                    color="gray"
                    mb={"2px"}
                    _hover={{ color: "red.500" }}
                    cursor="pointer"
                  />
                </Box>
              </HStack>
            );
          })
        ) : (
          <Box
            border="1px"
            h="2.5rem"
            borderColor="whiteAlpha.100"
            alignContent="center"
            px={4}
            w="full"
          >
            <Text color="whiteAlpha.300">No accounts added yet</Text>
          </Box>
        )}
      </VStack>
    </VStack>
  );
};

export default AccountsInput;
