import { useContext } from "react";
import { HStack, VStack, Text } from "@chakra-ui/react";
import { Context } from "../../App";
import { numberWithCommas, capitalize } from "../../utils";
import { ChangeArrow } from "../reusable";

const DebtWidget = () => {
  const context = useContext(Context);

  const debtAccounts = context.accounts.filter((acc) =>
    ["credit", "loan"].includes(acc.type)
  );
  const prevdebtAccounts = context.previous?.accounts?.filter((acc) =>
    ["credit", "loan"].includes(acc.type)
  );

  if (!debtAccounts || debtAccounts.length === 0) return null;

  const total = debtAccounts.reduce((prev, curr) => {
    if (curr.balance && curr.balance > 0) {
      return prev + curr.balance;
    } else {
      return prev;
    }
  }, 0);
  const prevtotal = prevdebtAccounts?.reduce((prev, curr) => {
    if (curr.balance && curr.balance > 0) {
      return prev + curr.balance;
    } else {
      return prev;
    }
  }, 0);

  const sortedAccounts = debtAccounts.sort(
    (a, b) => b.interestRate - a.interestRate
  );

  const breakdown = sortedAccounts.reduce((prev, curr) => {
    if (curr.balance && curr.balance > 0) {
      const negativeTypes = ["credit", "loan"];
      if (negativeTypes.includes(curr.type)) {
        return [
          ...prev,
          `${
            curr.nickname ? `${curr.nickname} ` : `${capitalize(curr.type)} `
          }(${curr.balance ? `$${numberWithCommas(curr.balance)}` : ""}${
            curr.interestRate ? `-${curr.interestRate}%` : ""
          })`,
        ];
      } else {
        return prev;
      }
    } else {
      return prev;
    }
  }, []);

  return (
    <VStack w="full" spacing={0} align="start">
      <HStack w="full" justify="space-between">
        <HStack spacing={1}>
          <Text color="whiteAlpha.800">All Debt:</Text>
          <Text color="whiteAlpha.900">
            <b>${numberWithCommas(total)}</b>
          </Text>
        </HStack>
        <ChangeArrow prev={prevtotal} curr={total} isDebt />
      </HStack>
      <Text fontSize="sm" color="whiteAlpha.600">
        {breakdown.join(", ")}
      </Text>
    </VStack>
  );
};

export default DebtWidget;
