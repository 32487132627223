import { Box, VStack, Text, Divider, Image } from "@chakra-ui/react";
import {
  AllowanceWidget,
  CheckingWidget,
  CreditScoreWidget,
  CreditWidget,
  DebtWidget,
  GivingWidget,
  GoalWidget,
  IncomeWidget,
  NetWorthWidget,
} from "../widgets";
import dayjs from "dayjs";
import { useContext } from "react";
import { Context } from "../../App";
import exampleCard from "../../assets/example_card.png";

const GrowCard = ({ noData }) => {
  const context = useContext(Context);

  if (noData)
    return (
      <Box
        maxW="400px"
        w={{ base: "auto", sm: "400px", lg: "400px" }}
        minW="300px"
        style={{ boxShadow: "10px 15px 15px 5px rgba(0,0,0,.5)" }}
      >
        <Image src={exampleCard} />
      </Box>
    );

  return (
    <Box
      bg="#1e1e1e"
      border="2px"
      borderColor="whiteAlpha.400"
      maxW="400px"
      w={{ base: "auto", sm: "400px", lg: "400px" }}
      minW="300px"
      ps={8}
      pt={8}
      pe={8}
      style={{ boxShadow: "10px 15px 15px 5px rgba(0,0,0,.5)" }}
    >
      <VStack spacing={5} align="start">
        <GoalWidget />
        {context.goal ? <Divider borderColor="whiteAlpha.400" /> : null}
        <NetWorthWidget />
        <IncomeWidget />
        <CheckingWidget />
        <CreditWidget />
        <DebtWidget />
        <AllowanceWidget />
        <GivingWidget />
        <CreditScoreWidget />
      </VStack>
      <Text
        w="full"
        color="whiteAlpha.500"
        fontSize="sm"
        textAlign="center"
        pt={5}
        pb={1}
      >
        <i>
          {context.people.map(
            (p, i) =>
              `${p.firstName ?? ""}${p.lastName ? " " + p.lastName : ""}${
                i !== context.people.length - 1 ? " + " : ""
              }`
          )}
          {context.people?.some((p) => p.firstName || p.lastName) ? " - " : ""}
          {dayjs().format("MM/DD/YYYY")}
        </i>
      </Text>
    </Box>
  );
};

export default GrowCard;
