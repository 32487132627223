import { useContext } from "react";
import { HStack, VStack, Text } from "@chakra-ui/react";
import { Context } from "../../App";
import { numberWithCommas } from "../../utils";
import { ChangeArrow } from "../reusable";

const IncomeWidget = () => {
  const context = useContext(Context);

  const total = context.income.reduce((prev, curr) => {
    if (curr?.amount > 0) {
      return prev + curr.amount;
    } else {
      return prev;
    }
  }, 0);

  if (!total || total === 0) return null;

  const prevtotal = context.previous?.income?.reduce((prev, curr) => {
    if (curr?.amount > 0) {
      return prev + curr.amount;
    } else {
      return prev;
    }
  }, 0);

  const breakdown = context.income
    .filter((v) => v.source && v.amount)
    .sort((a, b) => b.amount - a.amount)
    .map((val) => {
      const percent = ((val.amount / total) * 100).toFixed(0);
      return `${percent}% ${val.source}`;
    });

  return (
    <VStack w="full" spacing={0} align="start">
      <HStack w="full" justify="space-between" align="start">
        <HStack spacing={1}>
          <Text color="whiteAlpha.800">Income: </Text>
          <Text color="whiteAlpha.900">
            <b>${numberWithCommas(total)}</b>
          </Text>
        </HStack>
        <ChangeArrow prev={prevtotal} curr={total} />
      </HStack>
      <Text fontSize="sm" color="whiteAlpha.600" alignSelf="flex-start">
        {breakdown.join(", ")}
      </Text>
    </VStack>
  );
};

export default IncomeWidget;
