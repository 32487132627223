import { useContext } from "react";
import { VStack, Text, HStack, Input } from "@chakra-ui/react";
import { Context } from "../../App";
import dayjs from "dayjs";

const NextPaycheckDateInput = () => {
  const context = useContext(Context);
  return (
    <VStack w="full" spacing={2}>
      <Text fontSize="sm" color="gray" w="full">
        Next Paycheck Date
      </Text>
      <HStack w="full" spacing={0}>
        <Input
          color={context.nextPaycheckDate ? "whiteAlpha.800" : "whiteAlpha.300"}
          borderColor="whiteAlpha.300"
          style={{ colorScheme: "dark" }}
          _hover={{ borderColor: "whiteAlpha.800" }}
          type="date"
          borderRadius={0}
          defaultValue={context.nextPaycheckDate}
          onChange={(e) => {
            const nextPaycheckDate = dayjs(e.target.value).format("YYYY-MM-DD");
            localStorage.setItem("nextPaycheckDate", nextPaycheckDate);
            context.setContext({
              ...context,
              nextPaycheckDate,
            });
          }}
        />
      </HStack>
    </VStack>
  );
};

export default NextPaycheckDateInput;
