import { useContext } from "react";
import { HStack, Text } from "@chakra-ui/react";
import { Context } from "../../App";
import { numberWithCommas } from "../../utils";
import { ChangeArrow } from "../reusable";

const GivingWidget = () => {
  const context = useContext(Context);

  if (!context.giving) return null;

  return (
    <HStack spacing={1} w="full" justify="space-between">
      <HStack spacing={1} w="full">
        <Text color="whiteAlpha.800">Giving: </Text>
        <Text color="whiteAlpha.900">
          <b>
            ${numberWithCommas(context.giving)}
            {context.givingFrequency ? ` ${context.givingFrequency}` : ""}
          </b>
        </Text>
      </HStack>
      <ChangeArrow prev={context.previous?.giving} curr={context.giving} />
    </HStack>
  );
};

export default GivingWidget;
