import { useContext } from "react";
import { Context } from "../../App";
import { HStack, VStack, Text, Input } from "@chakra-ui/react";
import { HelperTooltip } from "../reusable";
import dayjs from "dayjs";

const GoalInput = () => {
  const context = useContext(Context);
  return (
    <VStack w="full" spacing={2}>
      <VStack w="full" spacing={1}>
        <HStack w="full" justifyContent="space-between">
          <Text fontSize="sm" color="gray">
            Goal
          </Text>
          <HelperTooltip />
        </HStack>
        <Input
          color="whiteAlpha.800"
          borderColor="whiteAlpha.300"
          _hover={{ borderColor: "whiteAlpha.800" }}
          borderRadius={0}
          defaultValue={context.goal}
          onChange={(e) => {
            localStorage.setItem("goal", e.target.value);
            context.setContext({ ...context, goal: e.target.value });
          }}
        />
      </VStack>
      <VStack w="full" spacing={1}>
        <Text fontSize="sm" color="gray" w="full">
          Target Date
        </Text>
        <Input
          color={context.goalTargetDate ? "whiteAlpha.800" : "whiteAlpha.300"}
          style={{ colorScheme: "dark" }}
          _focus={{ color: "whiteAlpha.800" }}
          borderColor="whiteAlpha.300"
          _hover={{ borderColor: "whiteAlpha.800" }}
          type="date"
          borderRadius={0}
          defaultValue={context.goalTargetDate}
          onChange={(e) => {
            const goalTargetDate = dayjs(e.target.value).format("YYYY-MM-DD");
            localStorage.setItem("goalTargetDate", goalTargetDate);
            context.setContext({
              ...context,
              goalTargetDate,
            });
          }}
        />
      </VStack>
    </VStack>
  );
};

export default GoalInput;
