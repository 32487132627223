import { useContext } from "react";
import { HStack, VStack, Text } from "@chakra-ui/react";
import { Context } from "../../App";
import { FrequencyPicker, NumInput } from "../reusable";

const AllowanceInput = () => {
  const context = useContext(Context);

  return (
    <VStack w="full" spacing={1}>
      <Text fontSize="sm" color="gray" w="full">
        Allowance
      </Text>
      <HStack w="full" spacing={0}>
        <NumInput
          value={context.allowance}
          min={0}
          showDollarSign
          onChange={(val) => {
            localStorage.setItem("allowance", val);
            context.setContext({
              ...context,
              allowance: val,
            });
          }}
        />
        <FrequencyPicker
          value={context.allowanceFrequency}
          onChange={(val) => {
            localStorage.setItem("allowanceFrequency", val);
            context.setContext({
              ...context,
              allowanceFrequency: val,
            });
          }}
        />
      </HStack>
    </VStack>
  );
};

export default AllowanceInput;
