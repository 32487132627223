import { HStack, Icon, Text } from "@chakra-ui/react";
import { numberWithCommas } from "../../utils";
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";

const ChangeArrow = ({ prev, curr, percent = true, isDebt = false }) => {
  if (!prev || !curr) return null;

  const diff = curr - prev;

  let val = "";
  if (diff === 0) {
    return <Text color="whiteAlpha.600">-</Text>;
  } else if (percent) {
    const diffPercent = ((curr - prev) / prev) * 100;
    val = `${numberWithCommas(Math.abs(diffPercent.toFixed(0))).toString()}%`;
  } else {
    val = `${numberWithCommas(Math.abs(diff)).toString()}`;
  }

  const color =
    diff > 0 && isDebt
      ? "red.500"
      : diff > 0 && !isDebt
      ? "green.500"
      : diff < 0 && isDebt
      ? "green.500"
      : "red.500";

  return (
    <HStack spacing={1}>
      <Text color={color}>{val}</Text>
      {diff > 0 ? (
        <Icon as={AiOutlineCaretUp} color={color} />
      ) : (
        <Icon as={AiOutlineCaretDown} color={color} />
      )}
    </HStack>
  );
};

export default ChangeArrow;
