import { HStack, Text } from "@chakra-ui/react";
import { useContext } from "react";
import { Context } from "../../App";
import { numberWithCommas } from "../../utils";
import { ChangeArrow } from "../reusable";

const NetWorthWidget = () => {
  const context = useContext(Context);

  const total = context.accounts.reduce((prev, curr) => {
    if (curr?.balance > 0) {
      const positiveTypes = ["checking", "investment", "savings"];
      const negativeTypes = ["credit", "loan"];
      if (positiveTypes.includes(curr.type)) {
        return prev + curr.balance;
      } else if (negativeTypes.includes(curr.type)) {
        return prev - curr.balance;
      } else {
        return prev;
      }
    } else {
      return prev;
    }
  }, 0);

  if (!total) return null;

  const prevtotal = context.previous?.accounts?.reduce((prev, curr) => {
    if (curr?.balance > 0) {
      const positiveTypes = ["checking", "investment", "savings"];
      const negativeTypes = ["credit", "loan"];
      if (positiveTypes.includes(curr.type)) {
        return prev + curr.balance;
      } else if (negativeTypes.includes(curr.type)) {
        return prev - curr.balance;
      } else {
        return prev;
      }
    } else {
      return prev;
    }
  }, 0);

  return (
    <HStack spacing={1} w="full" justify="space-between" textAlign="start">
      <HStack spacing={1} w="full" textAlign="start">
        <Text color="whiteAlpha.800">Net Worth: </Text>
        <Text color="whiteAlpha.900">
          <b>
            {total > 0
              ? `$${numberWithCommas(total)}`
              : `-$${numberWithCommas(Math.abs(total))}`}
          </b>
        </Text>
      </HStack>
      <ChangeArrow prev={prevtotal} curr={total} />
    </HStack>
  );
};

export default NetWorthWidget;
