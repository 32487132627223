import { useContext } from "react";
import { HStack, VStack, Text } from "@chakra-ui/react";
import { Context } from "../../App";
import { ChangeArrow } from "../reusable";

const CreditScoreWidget = () => {
  const context = useContext(Context);

  const hasData = context.creditScores.filter((cs) => cs.score);

  if (
    !context.creditScores ||
    context.creditScores.length === 0 ||
    hasData.length === 0
  )
    return null;

  return (
    <VStack spacing={1} w="full" align="start">
      {hasData.map((cs, i) => {
        const person = context.people.find((p) => p.id === cs.personId);
        const personName = person?.firstName ?? null;
        const prevScore = context.previous?.creditScores?.find(
          (pcs) => pcs.personId === cs.personId
        )?.score;
        return (
          <HStack key={cs.id} spacing={1} w="full" justify="space-between">
            <HStack spacing={1} w="full">
              <Text color="whiteAlpha.800">
                {personName ?? `Person ${i + 1}`} Credit Score:
              </Text>
              <Text color="whiteAlpha.900">
                <b>{cs.score}</b>
              </Text>
            </HStack>
            <ChangeArrow prev={prevScore} curr={cs.score} percent={false} />
          </HStack>
        );
      })}
    </VStack>
  );
};

export default CreditScoreWidget;
