import { HStack, VStack, Text } from "@chakra-ui/react";
import { useContext } from "react";
import { Context } from "../../App";
import { ChangeArrow } from "../reusable";
import {
  capitalize,
  numberWithCommas,
  getAccountTypeTotal,
  moveSharedAccountToTop,
} from "../../utils";
import dayjs from "dayjs";

const CheckingWidget = () => {
  const context = useContext(Context);
  const accounts = context.accounts.filter((act) => act.type === "checking");
  const prevAccounts =
    context?.previous?.accounts?.filter((act) => act.type === "checking") || [];

  if (!accounts || accounts.length === 0) return null;

  const sortedAccounts = moveSharedAccountToTop(accounts);

  return (
    <VStack align="start" w="full" spacing={1}>
      {sortedAccounts.map((act) => {
        const owner = context.people.find((p) => p.id === act.owner);
        const ownerFirstName = owner?.firstName.toLowerCase();

        let prevtotal = 0;
        if (owner) {
          prevtotal = getAccountTypeTotal(
            prevAccounts,
            "checking",
            context?.previous?.people,
            ownerFirstName
          );
        } else {
          prevtotal = getAccountTypeTotal(prevAccounts, "checking");
        }

        const total = getAccountTypeTotal(
          sortedAccounts,
          "checking",
          context.people,
          ownerFirstName
        );

        return (
          <VStack
            key={`checking-${act.id || "Shared"}`}
            align="start"
            w="full"
            spacing={0}
          >
            <HStack w="full" justify="space-between">
              <HStack spacing={1}>
                <Text color="whiteAlpha.800">
                  {owner ? capitalize(owner.firstName) + " " : ""}Checking:
                </Text>
                <Text color="whiteAlpha.900">
                  <b>
                    {act.balance ? `$${numberWithCommas(act.balance)}` : ""}
                  </b>
                </Text>
              </HStack>
              <ChangeArrow prev={prevtotal} curr={total} />
            </HStack>
            {context.nextPaycheckDate && !owner ? (
              <Text fontSize="sm" color="whiteAlpha.600">
                Next Paycheck {dayjs(context.nextPaycheckDate).fromNow()}
              </Text>
            ) : null}
          </VStack>
        );
      })}
    </VStack>
  );
};

export default CheckingWidget;
