export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const capitalize = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const getInitials = (person) => {
  const firstInitial = person?.firstName?.charAt(0) ?? "";
  const lastInitial = person?.lastName?.charAt(0) ?? "";
  return firstInitial + lastInitial;
};

export const generateId = () => {
  return (Math.random() * 10000000000000).toString();
};

export const getAccountTypeTotal = (
  acts,
  type,
  people = [],
  personFirstName = null
) => {
  if (!acts || acts.length === 0) return 0;
  return acts.reduce((prev, curr) => {
    if (curr?.balance > 0) {
      const currOwner = people.find((p) => p.id === curr.owner);
      const currOwnerFirstName = currOwner?.firstName.toLowerCase();
      if (curr.type === type) {
        if (personFirstName && personFirstName === currOwnerFirstName) {
          return prev + curr.balance;
        } else if (!personFirstName) {
          return prev + curr.balance;
        }
        return prev;
      } else {
        return prev;
      }
    } else {
      return prev;
    }
  }, 0);
};

export const moveSharedAccountToTop = (acts) => {
  // Find the index of the account with no owner
  const index = acts.findIndex((account) => account.owner === null);

  // If no such account is found, return the original array
  if (index === -1) {
    return acts;
  }

  // Extract the account with no owner
  const [accountWithNoOwner] = acts.splice(index, 1);

  // Move the account to the top
  acts.unshift(accountWithNoOwner);

  return acts;
};
