import { Box, HStack, Button, Input, VStack, Text } from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { generateId } from "../../utils";
import { useContext } from "react";
import { Context } from "../../App";

export const PeopleInput = () => {
  const context = useContext(Context);

  const addPerson = () => {
    let upd = structuredClone(context.people);

    const id = generateId();
    const blank = {
      id,
      firstName: null,
      lastName: null,
    };

    if (
      context?.people?.length > 0 &&
      typeof context.people[0] !== "undefined"
    ) {
      upd = [...context.people, blank];
    } else {
      upd = [blank];
    }

    const cs = structuredClone(context.creditScores);
    const csId = generateId();
    cs.push({ id: csId, score: null, personId: id });

    context.setContext({ ...context, creditScores: cs, people: upd });
    localStorage.setItem("people", JSON.stringify(upd));
    localStorage.setItem("creditScores", JSON.stringify(cs));
  };

  const updateValue = (field, newVal, i) => {
    let upd = [...context.people];
    upd[i][field] = newVal;
    context.setContext({ ...context, people: upd });
    localStorage.setItem("people", JSON.stringify(upd));
  };

  const deletePerson = (id) => {
    let upd = context.people.filter((v) => v.id !== id);

    const upd2 = structuredClone(context.creditScores);
    const newT = upd2.filter((u) => u.personId !== id);

    localStorage.setItem("people", JSON.stringify(upd));
    localStorage.setItem("creditScores", JSON.stringify(newT));
    context.setContext({ ...context, people: upd, creditScores: newT });
  };

  return (
    <VStack w="full" spacing={1}>
      <HStack w="full" justifyContent="space-between">
        <Text fontSize="sm" color="gray">
          People
        </Text>
        <Button
          size="sm"
          variant="link"
          transition="all 0.2s"
          color={"gray"}
          _hover={{ color: "whiteAlpha.800" }}
          _active={{ color: "whiteAlpha.800" }}
          onClick={() => addPerson()}
        >
          Add
        </Button>
      </HStack>
      <VStack spacing={2} w="full">
        {context.people?.length > 0 ? (
          context.people.map((person, i) => {
            return (
              <HStack key={person.id} spacing={0} w="full">
                <Input
                  color="whiteAlpha.800"
                  borderColor="whiteAlpha.300"
                  _hover={{ borderColor: "whiteAlpha.800" }}
                  borderRadius={0}
                  placeholder="First name"
                  defaultValue={person.firstName}
                  _placeholder={{ color: "whiteAlpha.300" }}
                  onChange={(e) => updateValue("firstName", e.target.value, i)}
                />
                <Input
                  color="whiteAlpha.800"
                  borderColor="whiteAlpha.300"
                  _hover={{ borderColor: "whiteAlpha.800" }}
                  borderRadius={0}
                  placeholder="Last name"
                  defaultValue={person.lastName}
                  _placeholder={{ color: "whiteAlpha.300" }}
                  onChange={(e) => updateValue("lastName", e.target.value, i)}
                />
                <Box
                  border="1px"
                  h="2.5rem"
                  borderColor="whiteAlpha.300"
                  alignContent="center"
                  px={4}
                  onClick={() => deletePerson(person.id)}
                >
                  <DeleteIcon
                    color="gray"
                    mb={"2px"}
                    _hover={{ color: "red.500" }}
                    cursor="pointer"
                  />
                </Box>
              </HStack>
            );
          })
        ) : (
          <Box
            border="1px"
            h="2.5rem"
            borderColor="whiteAlpha.100"
            alignContent="center"
            px={4}
            w="full"
          >
            <Text color="whiteAlpha.300">No people added yet</Text>
          </Box>
        )}
      </VStack>
    </VStack>
  );
};

export default PeopleInput;
