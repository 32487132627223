import { useContext } from "react";
import { HStack, Text, VStack } from "@chakra-ui/react";
import { Context } from "../../App";
import {
  numberWithCommas,
  moveSharedAccountToTop,
  getAccountTypeTotal,
  capitalize,
} from "../../utils";
import { ChangeArrow } from "../reusable";

const CreditWidget = () => {
  const context = useContext(Context);
  const accounts = context.accounts.filter((act) => act.type === "credit");
  const prevAccounts =
    context?.previous?.accounts?.filter((act) => act.type === "credit") || [];

  if (!accounts || accounts.length === 0) return null;

  const sortedAccounts = moveSharedAccountToTop(accounts);

  const limit = accounts.reduce((prev, curr) => {
    if (curr.balance && curr.balance > 0) {
      if (curr.type === "credit") {
        return prev + curr.limit;
      } else {
        return prev;
      }
    } else {
      return prev;
    }
  }, 0);

  return (
    <VStack align="start" w="full" spacing={1}>
      {sortedAccounts.map((act) => {
        const owner = context.people.find((p) => p.id === act.owner);
        const ownerFirstName = owner?.firstName.toLowerCase();

        let prevtotal = 0;
        if (owner) {
          prevtotal = getAccountTypeTotal(
            prevAccounts,
            "credit",
            context?.previous?.people,
            ownerFirstName
          );
        } else {
          prevtotal = getAccountTypeTotal(prevAccounts, "credit");
        }

        const total = getAccountTypeTotal(
          sortedAccounts,
          "credit",
          context.people,
          ownerFirstName
        );

        return (
          <VStack
            key={`credit-${act.id || "Shared"}`}
            align="start"
            w="full"
            spacing={0}
          >
            <HStack spacing={1} w="full" justify="space-between">
              <HStack spacing={1} w="full">
                <Text color="whiteAlpha.800">
                  {owner ? capitalize(owner.firstName) + " " : ""}Credit:{" "}
                </Text>
                <Text color="whiteAlpha.900">
                  <b>
                    $
                    {numberWithCommas(total) +
                      (act.limit
                        ? ` of $${numberWithCommas(act.limit)} limit`
                        : "")}
                  </b>
                </Text>
              </HStack>
              <ChangeArrow prev={prevtotal} curr={total} isDebt />
            </HStack>
          </VStack>
        );
      })}
    </VStack>
  );
};

export default CreditWidget;
