import {
  InputLeftElement,
  InputRightElement,
  InputGroup,
  Input,
} from "@chakra-ui/react";

const NumInput = ({
  value,
  onChange,
  min = undefined,
  placeholder = undefined,
  showDollarSign = false,
  showPercentSign = false,
}) => {
  return (
    <InputGroup w="full">
      {showDollarSign ? (
        <InputLeftElement
          pointerEvents="none"
          color="whiteAlpha.300"
          fontSize="1.2em"
        >
          $
        </InputLeftElement>
      ) : null}
      <Input
        type="number"
        color="whiteAlpha.800"
        borderColor="whiteAlpha.300"
        min={min}
        borderRadius={0}
        _hover={{ borderColor: "whiteAlpha.800" }}
        value={value ?? ""}
        onChange={(e) =>
          onChange(e.target.value ? Number(e.target.value) : null)
        }
        placeholder={placeholder}
        _placeholder={{ color: "whiteAlpha.300" }}
      />
      {showPercentSign ? (
        <InputRightElement
          pointerEvents="none"
          color="whiteAlpha.300"
          fontSize="1.2em"
        >
          %
        </InputRightElement>
      ) : null}
    </InputGroup>
  );
};

export default NumInput;
