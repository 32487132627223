import { useContext } from "react";
import { HStack, VStack, Text } from "@chakra-ui/react";
import { Context } from "../../App";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

const GoalWidget = () => {
  const context = useContext(Context);

  if (!context.goal) return null;

  return (
    <VStack spacing={0} alignItems="start">
      <HStack spacing={1} align="start">
        <Text color="whiteAlpha.800">Goal: </Text>
        <Text color="whiteAlpha.900">
          <b>{context.goal}</b>
        </Text>
      </HStack>
      {context.goalTargetDate ? (
        <Text fontSize="sm" color="whiteAlpha.600">
          {dayjs(context.goalTargetDate).fromNow(true)} left
        </Text>
      ) : null}
    </VStack>
  );
};

export default GoalWidget;
