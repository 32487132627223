import { useContext } from "react";
import { HStack, Text } from "@chakra-ui/react";
import { Context } from "../../App";
import { numberWithCommas } from "../../utils";
import { ChangeArrow } from "../reusable";

const AllowanceWidget = () => {
  const context = useContext(Context);

  if (!context.allowance) return null;

  return (
    <HStack spacing={1} w="full" justify="space-between">
      <HStack spacing={1}>
        <Text color="whiteAlpha.800">Allowance: </Text>
        <Text color="whiteAlpha.900">
          <b>
            ${numberWithCommas(context.allowance)}{" "}
            {context.allowanceFrequency ?? ""} / person
          </b>
        </Text>
      </HStack>
      <ChangeArrow
        prev={context.previous?.allowance}
        curr={context.allowance}
      />
    </HStack>
  );
};

export default AllowanceWidget;
