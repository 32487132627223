import "./App.css";
import { useState, createContext, useEffect } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Container,
  Tag,
  HStack,
  Stack,
  Text,
  VStack,
  useDisclosure,
  IconButton,
} from "@chakra-ui/react";
import { ArrowUpIcon, ArrowDownIcon } from "@chakra-ui/icons";
import {
  AccountsInput,
  AllowanceInput,
  CreditScoreInput,
  GoalInput,
  IncomeInput,
  NextPaycheckDateInput,
  PeopleInput,
  GivingInput,
} from "./components/inputs";
import { GrowCard, ResetDialog } from "./components/reusable";
import { FiCheck, FiDownload, FiUpload, FiX } from "react-icons/fi";
import dayjs from "dayjs";

function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export const Context = createContext();

function App() {
  const [context, setContext] = useState({
    goal: null,
    progress: null,
    nextPaycheckDate: null,
    giving: null,
    givingFrequency: null,
    allowance: null,
    income: [],
    allowanceFrequency: null,
    accounts: [],
    people: [],
    creditScores: [],
    previous: {},
  });
  const [loaded, setLoaded] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [showPitch, setShowPitch] = useState(true);

  const [selectedFile, setSelectedFile] = useState(null);

  // Function to handle file selection
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // Function to handle file upload
  const handleUpload = () => {
    const file = selectedFile; // Get the selected file
    if (file) {
      const reader = new FileReader();

      reader.onload = function (e) {
        try {
          const json = JSON.parse(e.target.result); // Parse the JSON string into an object
          setContext({ ...context, previous: json });
          localStorage.setItem("previous", JSON.stringify(json));
          console.log("Parsed JSON:", json); // Do something with the JSON object
        } catch (error) {
          console.error("Error parsing JSON:", error);
        }
      };

      reader.onerror = function (e) {
        console.error("File reading error:", e.target.error);
      };

      reader.readAsText(file); // Read the file as a text
    } else {
      console.log("No file selected");
    }
  };
  useEffect(() => {
    if (selectedFile) {
      handleUpload();
    }
  }, [selectedFile]);

  useEffect(() => {
    if (!loaded) {
      const goal = localStorage.getItem("goal");
      const goalTargetDate = localStorage.getItem("goalTargetDate");
      const nextPaycheckDate = localStorage.getItem("nextPaycheckDate");
      const giving = localStorage.getItem("giving");
      const givingFrequency = localStorage.getItem("givingFrequency");
      const allowance = localStorage.getItem("allowance");
      const allowanceFrequency = localStorage.getItem("allowanceFrequency");
      const income = JSON.parse(localStorage.getItem("income")) ?? [];
      const accounts = JSON.parse(localStorage.getItem("accounts")) ?? [];
      const people = JSON.parse(localStorage.getItem("people")) ?? [];
      const creditScores =
        JSON.parse(localStorage.getItem("creditScores")) ?? [];
      const previous = JSON.parse(localStorage.getItem("previous")) ?? {};

      setContext({
        goal,
        goalTargetDate,
        giving,
        givingFrequency,
        nextPaycheckDate,
        income,
        allowance,
        allowanceFrequency,
        accounts,
        people,
        creditScores,
        previous,
      });
      setLoaded(true);
    }
  }, [loaded]);

  const download = () => {
    const data = {
      accounts: context.accounts,
      goal: context.goal,
      goalTargetDate: context.goalTargetDate,
      giving: context.giving,
      givingFrequency: context.givingFrequency,
      allowance: context.allowance,
      allowanceFrequency: context.allowanceFrequency,
      income: context.income,
      people: context.people,
      creditScores: context.creditScores,
    };

    // Convert JSON to text
    const text = JSON.stringify(data);

    // Create a Blob object with the text
    const blob = new Blob([text], { type: "text/plain" });

    // Create a temporary anchor element
    const anchor = document.createElement("a");
    anchor.download = `GrowCard_${dayjs().format("MM_DD_YYYY")}.json`;
    anchor.href = window.URL.createObjectURL(blob);
    anchor.target = "_blank";

    // Simulate a click on the anchor element to initiate download
    anchor.click();

    // Clean up
    window.URL.revokeObjectURL(anchor.href);
  };

  const noData =
    !context.goal &&
    !context.giving &&
    !context.allowance &&
    (!context.income || context.income.length === 0) &&
    (!context.accounts || context.accounts.length === 0) &&
    (!context.people || context.people.length === 0) &&
    (!context.creditScores || context.creditScores.length === 0);

  if (!loaded) return null;

  return (
    <div className="App">
      <Context.Provider value={{ ...context, setContext }}>
        <ResetDialog isOpen={isOpen} onClose={onClose} />
        <Container py={10} maxW="container.lg">
          <Stack
            w="full"
            spacing={12}
            alignItems={{ base: "stretch", md: "start" }}
            direction={{ base: "column", md: "row" }}
          >
            <VStack
              position={{ base: "none", md: "sticky" }}
              top={{ base: "none", md: 10 }}
            >
              <GrowCard noData={noData} />
              {!noData ? (
                <Button
                  variant="ghost"
                  color="gray"
                  fontSize="sm"
                  onClick={onOpen}
                  backgroundColor="none"
                  _hover={{ backgroundColor: "none", color: "red.500" }}
                >
                  Reset
                </Button>
              ) : null}
            </VStack>
            <VStack w="full" align="start" spacing={10}>
              {showPitch ? (
                <VStack
                  p={6}
                  bg="whiteAlpha.50"
                  spacing={4}
                  w="full"
                  align="start"
                >
                  <HStack w="full" justify="space-between">
                    <Text color="whiteAlpha.800">What is GrowCard?</Text>
                    <IconButton
                      icon={<FiX fontSize="1.3em" />}
                      variant="link"
                      onClick={() => setShowPitch(false)}
                      color="whiteAlpha.300"
                      _hover={{ color: "whiteAlpha.700" }}
                      p={0}
                    />
                  </HStack>
                  <Text color="whiteAlpha.800" as="b">
                    GrowCard empowers you to take control of your finances.
                  </Text>
                  <Text color="whiteAlpha.700">
                    It's a no-nonsense snapshot of your finances that can help
                    you learn good habits. It also prepares you for fruitful
                    conversations with friends, family or consultants so you can
                    grow.
                  </Text>
                </VStack>
              ) : null}

              <VStack w="full" spacing={6}>
                <HStack w="full" justify="space-between" mb={0}>
                  <Text color="whiteAlpha.700" as="b">
                    How it works
                  </Text>
                  {!showPitch ? (
                    <Text
                      color="whiteAlpha.500"
                      fontSize="sm"
                      onClick={() => setShowPitch(true)}
                      cursor="pointer"
                      _hover={{ color: "whiteAlpha.700" }}
                    >
                      What is GrowCard?
                    </Text>
                  ) : null}
                </HStack>

                <Accordion
                  allowToggle
                  w="full"
                  borderColor="gray.800"
                  px={0}
                  mx={0}
                >
                  <AccordionItem pb={1} pt={0}>
                    <AccordionButton
                      color="whiteAlpha.600"
                      _hover={{ color: "whiteAlpha.800" }}
                      px={0}
                      w="fit-content"
                      _expanded={{ color: "whiteAlpha.800" }}
                      pt={0}
                    >
                      <Tag
                        bg="whiteAlpha.200"
                        color="whiteAlpha.700"
                        me={4}
                        borderRadius="0"
                        fontWeight="semibold"
                      >
                        1
                      </Tag>{" "}
                      Build your card
                      <AccordionIcon ms={1} />
                    </AccordionButton>
                    <AccordionPanel px={0}>
                      <VStack w="full" align="start" spacing={10}>
                        <GoalInput />
                        <PeopleInput />
                        <AccountsInput />
                        <IncomeInput />
                        <NextPaycheckDateInput />
                        <AllowanceInput />
                        <GivingInput />
                        <CreditScoreInput />
                      </VStack>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem py={1}>
                    <HStack justify="space-between">
                      <AccordionButton
                        color="whiteAlpha.600"
                        _hover={{ color: "whiteAlpha.800" }}
                        _expanded={{ color: "whiteAlpha.800" }}
                        px={0}
                        w="fit-content"
                      >
                        <Tag
                          bg="whiteAlpha.100"
                          color="whiteAlpha.700"
                          me={4}
                          borderRadius="0"
                          fontWeight="semibold"
                        >
                          2
                        </Tag>{" "}
                        See how your finances changed
                        <AccordionIcon ms={1} />
                      </AccordionButton>
                    </HStack>
                    <AccordionPanel px={0}>
                      <VStack w="full" align="start" spacing={5}>
                        <Text color="whiteAlpha.700">
                          If you saved your card from last time, upload it to
                          see colored arrows like{" "}
                          <ArrowUpIcon color="green" fontSize="1.2em" />
                          <ArrowDownIcon color="red" fontSize="1.2em" /> that
                          show how your money is changing.
                        </Text>
                        <input
                          type="file"
                          accept=".json"
                          onChange={handleFileChange}
                          style={{ display: "none" }}
                          id="fileInput"
                        />
                        <label htmlFor="fileInput">
                          <Button
                            colorScheme="gray"
                            variant="outline"
                            as="span"
                            borderColor="whiteAlpha.300"
                            color="whiteAlpha.700"
                            _hover={{
                              bg: selectedFile ? "none" : "whiteAlpha.100",
                            }}
                            cursor="pointer"
                            borderRadius={0}
                            leftIcon={
                              selectedFile || !isEmpty(context.previous) ? (
                                <FiCheck color="green" />
                              ) : (
                                <FiUpload />
                              )
                            }
                          >
                            {selectedFile || !isEmpty(context.previous)
                              ? "Good to go!"
                              : "Upload previous card"}
                          </Button>
                        </label>
                      </VStack>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem py={1}>
                    <AccordionButton
                      px={0}
                      color="whiteAlpha.600"
                      _hover={{ color: "whiteAlpha.800" }}
                      _expanded={{ color: "whiteAlpha.800" }}
                      w="fit-content"
                    >
                      <Tag
                        bg="whiteAlpha.100"
                        color="whiteAlpha.700"
                        me={4}
                        borderRadius="0"
                        fontWeight="semibold"
                      >
                        3
                      </Tag>{" "}
                      Save for next time
                      <AccordionIcon ms={1} />
                    </AccordionButton>
                    <AccordionPanel px={0}>
                      <VStack w="full" align="start" spacing={5}>
                        <Text color="whiteAlpha.700" w="full">
                          Save your current card so that you can upload it next
                          time and see how your finances changed.
                        </Text>
                        <Button
                          colorScheme="gray"
                          variant="outline"
                          as="span"
                          borderColor="whiteAlpha.300"
                          color="whiteAlpha.700"
                          _hover={{
                            bg: "whiteAlpha.100",
                          }}
                          cursor="pointer"
                          borderRadius={0}
                          leftIcon={<FiDownload />}
                          onClick={download}
                        >
                          Save Card
                        </Button>
                      </VStack>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </VStack>
            </VStack>
          </Stack>
        </Container>
      </Context.Provider>
    </div>
  );
}

export default App;
