import { useContext } from "react";
import { Box, Button, HStack, VStack, Text, Input } from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { NumInput } from "../reusable";
import { generateId } from "../../utils";
import { Context } from "../../App";

const IncomeInput = () => {
  const context = useContext(Context);

  const addIncome = () => {
    let upd = structuredClone(context.income);

    const id = generateId();
    const blank = {
      id,
      source: null,
      amount: null,
    };

    if (
      context.income?.length > 0 &&
      typeof context.income[0] !== "undefined"
    ) {
      upd = [...upd, blank];
    } else {
      upd = [blank];
    }

    save(upd);
  };

  const updateIncome = (field, newVal, i) => {
    let upd = structuredClone(context.income);
    upd[i][field] = newVal;
    save(upd);
  };

  const deleteIncome = (i) => {
    const upd = structuredClone(context.income);
    save(upd.toSpliced(i, 1));
  };

  const save = (val) => {
    localStorage.setItem("income", JSON.stringify(val));
    context.setContext({
      ...context,
      income: val,
    });
  };

  return (
    <VStack spacing={1} w="full">
      <HStack w="full" justifyContent="space-between">
        <Text fontSize="sm" color="gray">
          Income
        </Text>
        <Button
          size="sm"
          variant="link"
          transition="all 0.2s"
          color={"gray"}
          _hover={{ color: "whiteAlpha.800" }}
          _active={{ color: "whiteAlpha.800" }}
          onClick={() => addIncome()}
        >
          Add
        </Button>
      </HStack>
      <VStack spacing={2} w="full">
        {context.income?.length > 0 ? (
          context.income.map((ib, i) => (
            <HStack key={ib.id} spacing={0} w="full" align="stretch">
              <Input
                color="whiteAlpha.800"
                borderColor="whiteAlpha.300"
                _hover={{ borderColor: "whiteAlpha.800" }}
                placeholder="Source"
                _placeholder={{ color: "whiteAlpha.300" }}
                borderRadius={0}
                defaultValue={ib.source}
                onChange={(e) => updateIncome("source", e.target.value, i)}
              />
              <NumInput
                value={ib.amount}
                showDollarSign
                onChange={(val) => updateIncome("amount", val, i)}
              />
              <Box
                border="1px"
                borderColor="whiteAlpha.300"
                px={4}
                onClick={() => deleteIncome(i)}
                alignContent="center"
              >
                <DeleteIcon
                  color="gray"
                  mb={"2px"}
                  _hover={{ color: "red.500" }}
                  cursor="pointer"
                />
              </Box>
            </HStack>
          ))
        ) : (
          <Box
            border="1px"
            h="2.5rem"
            borderColor="whiteAlpha.100"
            alignContent="center"
            px={4}
            w="full"
          >
            <Text color="whiteAlpha.300">No income added yet</Text>
          </Box>
        )}
      </VStack>
    </VStack>
  );
};

export default IncomeInput;
