import {
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { useContext } from "react";
import { Context } from "../../App";
import { FiUsers } from "react-icons/fi";
import { getInitials } from "../../utils";

const PersonPicker = ({ value, onChange }) => {
  const context = useContext(Context);
  const activePerson = context.people.find((p) => p.id === value);

  let buttonText = "";
  if (activePerson && (activePerson.firstName || activePerson.lastName)) {
    buttonText = getInitials(activePerson);
  } else {
    buttonText = <FiUsers size="1.2em" />;
  }

  return (
    <Menu>
      <MenuButton
        as={Button}
        minW="fit-content"
        variant="outline"
        transition="all 0.2s"
        borderRadius="none"
        backgroundColor="none"
        color="whiteAlpha.800"
        borderColor="whiteAlpha.300"
        borderWidth="1px"
        fontWeight="normal"
        _hover={{ borderColor: "whiteAlpha.800" }}
        _active={{ borderColor: "whiteAlpha.800", backgroundColor: "none" }}
      >
        {buttonText}
      </MenuButton>
      <MenuList
        backgroundColor="#1e1e1e"
        borderRadius="none"
        borderColor="whiteAlpha.300"
        color="whiteAlpha.800"
      >
        <MenuItem
          backgroundColor="#1e1e1e"
          _hover={{ backgroundColor: "whiteAlpha.100" }}
          onClick={() => onChange(null)}
        >
          <HStack spacing={4}>
            <FiUsers />
            <Text>Shared Account</Text>
          </HStack>
        </MenuItem>
        {context.people.map((p) => (
          <MenuItem
            key={p.firstName}
            backgroundColor="#1e1e1e"
            _hover={{ backgroundColor: "whiteAlpha.100" }}
            onClick={() => onChange(p.id)}
          >
            <HStack spacing={4}>
              <Text>{getInitials(p)}</Text>
              <Text>{p.firstName}</Text>
            </HStack>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default PersonPicker;
