import { HStack, VStack, Text } from "@chakra-ui/react";
import { useContext } from "react";
import { Context } from "../../App";
import { FrequencyPicker, NumInput } from "../reusable";

const GivingInput = () => {
  const context = useContext(Context);

  return (
    <VStack w="full" spacing={1}>
      <Text fontSize="sm" color="gray" w="full">
        Giving
      </Text>
      <HStack w="full" spacing={0}>
        <NumInput
          value={context.giving}
          min={0}
          showDollarSign
          onChange={(val) => {
            localStorage.setItem("giving", val);
            context.setContext({ ...context, giving: val });
          }}
        />
        <FrequencyPicker
          value={context.givingFrequency}
          onChange={(val) => {
            localStorage.setItem("givingFrequency", val);
            context.setContext({
              ...context,
              givingFrequency: val,
            });
          }}
        />
      </HStack>
    </VStack>
  );
};

export default GivingInput;
