import { Divider, VStack, Text, Icon, Tooltip } from "@chakra-ui/react";

const HelperTooltip = () => {
  return (
    <Tooltip
      p={3}
      backgroundColor="#1e1e1e"
      border="1px"
      borderColor="whiteAlpha.300"
      borderRadius="none"
      label={
        <VStack align="start" spacing={1}>
          <Text as="b" mb={3} color="whiteAlpha.800">
            A Basic Financial Strategy
          </Text>
          <Text fontSize="xs" color="whiteAlpha.600" mb={3}>
            This is based on wisdom from Dave Ramsey, Ms Dow Jones, and the life
            experience of the men at Grace City Portland church. Depending on
            your financial goals your steps may verify.
          </Text>
          <Divider borderColor="whiteAlpha.400" mb={3} />
          <Text color="whiteAlpha.800">
            Step 1: Build $1k Starter Emergency Fund
          </Text>
          <Text fontSize="xs" color="whiteAlpha.600" mb={3}>
            You're learning to be preventive not reactive.
          </Text>
          <Text color="whiteAlpha.800">
            Step 2: Pay Off All Debt Except Mortgage
          </Text>
          <Text fontSize="xs" color="whiteAlpha.600" mb={3}>
            It can feel empowering to pay off loans with low amounts first.
          </Text>
          <Text color="whiteAlpha.800">
            Step 3: Build 3-6 months emergency fund
          </Text>
          <Text fontSize="xs" color="whiteAlpha.600" mb={3}>
            If you lose your job you should not have to go back into debt.
          </Text>
          <Text color="whiteAlpha.800">Step 4: Invest 15% to Retirement</Text>
          <Text fontSize="xs" color="whiteAlpha.600" mb={3}>
            The longer your money has time to grow, the more you will have come
            retirement.
          </Text>
          <Text color="whiteAlpha.800">
            Step 5: Save 5% for kids college (if applicable)
          </Text>
          <Text fontSize="xs" color="whiteAlpha.600" mb={3}>
            Depending on your view of college, if you would like to pay for your
            child's, this is the time to start.
          </Text>
          <Text color="whiteAlpha.800">
            Step 6: Pay off your home (if applicable)
          </Text>
          <Text fontSize="xs" color="whiteAlpha.600" mb={3}>
            A mortgage is still debt.
          </Text>
          <Text color="whiteAlpha.800">
            Step 7: Live a life of freedom and abundant generosity
          </Text>
          <Text fontSize="xs" color="whiteAlpha.600" mb={3}>
            There's no stopping you!
          </Text>
        </VStack>
      }
    >
      <Icon color="gray" _hover={{ color: "whiteAlpha.800" }} />
    </Tooltip>
  );
};

export default HelperTooltip;
