import { useContext } from "react";
import { Box, HStack, VStack, Text, Input } from "@chakra-ui/react";
import { Context } from "../../App";
import { getInitials } from "../../utils";

const CreditScoreInput = () => {
  const context = useContext(Context);

  const updateValue = (newVal, i) => {
    let upd = structuredClone(context.creditScores);
    upd[i].score = newVal;

    save(upd);
  };

  const save = (val) => {
    localStorage.setItem("creditScores", JSON.stringify(val));
    context.setContext({
      ...context,
      creditScores: val,
    });
  };

  return (
    <VStack w="full" spacing={2}>
      <VStack w="full" spacing={1}>
        <Text fontSize="sm" color="gray" w="full">
          Credit Score{context?.people?.length > 1 ? "s" : ""}
        </Text>
        <VStack spacing={2} w="full">
          {context.creditScores.length === 0 ? (
            <Box
              border="1px"
              h="2.5rem"
              borderColor="whiteAlpha.100"
              alignContent="center"
              px={4}
              w="full"
            >
              <Text color="whiteAlpha.300">People must be added first</Text>
            </Box>
          ) : null}
          {context.creditScores.map((cs, i) => {
            const pers = context.people.find((p) => p.id === cs.personId);

            let abbrevName = "";
            if (pers && (pers.firstName || pers.lastName)) {
              abbrevName = getInitials(pers);
            } else {
              abbrevName = `Person ${i + 1}`;
            }

            return (
              <HStack w="full" key={`score-` + cs.id} spacing={0}>
                <Box
                  border="1px"
                  h="2.5rem"
                  borderColor="whiteAlpha.300"
                  alignContent="center"
                  px={4}
                  whiteSpace="nowrap"
                >
                  <Text color="whiteAlpha.800">{abbrevName}</Text>
                </Box>
                <Input
                  color="whiteAlpha.800"
                  borderColor="whiteAlpha.300"
                  _hover={{ borderColor: "whiteAlpha.800" }}
                  borderRadius={0}
                  defaultValue={cs.score}
                  onChange={(e) => updateValue(e.target.value, i)}
                />
              </HStack>
            );
          })}
        </VStack>
      </VStack>
    </VStack>
  );
};

export default CreditScoreInput;
